import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import LinearProgress from '@mui/material/LinearProgress';
import Skeleton from '@mui/material/Skeleton';

import formatDate from 'date-fns/format';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';

import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';


interface Product {
  id: string;
  name: string;
}


interface Dispenser {
  id: string;
  createdAt?: Date;
  updatedAt?: Date;
  bootedAt?: Date;
  lastSeenAt?: Date;

  name?: string;
  product?: Product;
  productName?: string;

  distance?: number;
  weight?: number;

  owner?: string;
}


const dispenserTableDef: {
  key: keyof Dispenser;
  headerName: string;
  align?: TableCellProps['align'],
  formatter?: (value: any) => any,
}[] = [
    {
      key: 'id',
      headerName: 'ID',
    },
    {
      key: 'name',
      headerName: 'Name',
    },
    {
      key: 'productName',
      headerName: 'Product',
    },
    {
      key: 'owner',
      headerName: 'Owner',
    },
    {
      key: 'weight',
      headerName: 'Weight',
      align: 'right',
    },
    {
      key: 'distance',
      headerName: 'Distance',
      align: 'right',
    },
    {
      key: 'lastSeenAt',
      headerName: 'Connected?',
      align: 'right',
      formatter: (value?: Date) => {
        if (value) {
          const now = new Date();
          const maxTimeUntilDisconnected = 7.5 * 60 * 1000;
          return (
            <Tooltip
              title={`${formatDistanceStrict(value, now)} ago at ${formatDate(value, 'ccc PPP ppp')}`}
              enterTouchDelay={0}
            >
              {now.getTime() - value.getTime() > maxTimeUntilDisconnected
                ? (
                  <LinkOffIcon sx={{ color: 'red' }} aria-label="Disconnected" />
                ) : (
                  <LinkIcon sx={{ color: 'green' }} aria-label="Connected" />
                )
              }
            </Tooltip>
          );
        }
        return '?'
      },
    },
  ];


const DispenserTable = ({
  dispensers,
  sortField = 'id',
  sortDirection = 'ascending',
  onDispenserSelected,
  loading = false,
}: {
  dispensers: Dispenser[];
  sortField?: keyof Dispenser;
  sortDirection?: 'ascending' | 'descending';
  onDispenserSelected?: (dispenserId: Dispenser['id']) => void;
  loading?: boolean;
}) => {
  const sortedValues = React.useMemo(
    () => dispensers.sort(
      (a, b) => {
        const v1 = a[sortField];
        const v2 = b[sortField];
        let cmp = 0;
        if (typeof v1 === 'string' && typeof v2 === 'string') {
          cmp = v1.localeCompare(v2);
        } else if (typeof v1 === 'number' && typeof v2 === 'number') {
          cmp = v2 - v1;
        } else if (v1 instanceof Date && v2 instanceof Date) {
          cmp = v2.getTime() - v1.getTime();
        }
        if (sortDirection === 'ascending') {
          return cmp;
        } else {
          return -cmp;
        }
      }
    ),
    [dispensers, sortField, sortDirection],
  )
  return (
    <TableContainer component={Paper}>
      {loading && <LinearProgress />}
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {dispenserTableDef.map((h) => (
              <TableCell key={h.key} align={h.align}>{h.headerName}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedValues.length < 1 ? (
            loading ? (
              <>
                {Array(5).fill(0).map((i, j) => (
                  <TableRow key={`${i}-${j}`}>
                    {dispenserTableDef.map((h, k) => (
                      <TableCell key={`${i}-${j}-${k}-${h.key}`}><Skeleton /></TableCell>
                    ))}
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell scope="row" align="center" colSpan={7}>
                  No data
                </TableCell>
              </TableRow>
            )
          ) : (sortedValues.map((dispenser) => (
            <TableRow
              key={dispenser.id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                cursor: onDispenserSelected ? 'pointer' : 'default',
              }}
              hover={true}
              onClick={() => onDispenserSelected && onDispenserSelected(dispenser.id)}
            >
              {dispenserTableDef.map((h) => (
                <TableCell key={h.key} align={h.align}>
                  {(h.formatter && h.formatter(dispenser[h.key])) || dispenser[h.key]}
                </TableCell>
              ))}
            </TableRow>
          )))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


const randomDispenserData = (): Dispenser => ({
  id: crypto.randomUUID().split('-')[0].toUpperCase(),
  name: `Randomly Generated Dispenser ${Math.round(Math.random() * 1000 + 1)}`,
  lastSeenAt: new Date(Date.now() - (1 * 3600 * 1000) * Math.random()),
});


function App() {
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'light',
        },
      }),
    [],
  );
  const [dispensers, setDispensers] = React.useState<Dispenser[]>([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(
    () => {
      setLoading(true);
      // Fake loading data
      setTimeout(() => {
        const fetchedDispensers = Array(10).fill(0).map(() => randomDispenserData());
        setDispensers(fetchedDispensers);
        setLoading(false);
      }, 1500);
    },
    [setDispensers],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth={false} sx={{ p: 3 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom={true}>
            Dispensers
          </Typography>
          <DispenserTable
            dispensers={dispensers}
            loading={loading}
          />

        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default App;
